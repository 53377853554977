import React, { Component } from 'react';

/**
  * This component represents the animated app logo
  */
export default class ServerList extends Component {

    componentDidMount () {
      
    }

    render() {
        return (
        	<div className="ServerList">
            <div className="ServerJoin">
              
            </div>
          </div>
        );  
    }
}